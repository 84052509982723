import React from "react";
import Helmet from "react-helmet";
import { Layout } from "../components/common";

const Contact = () => (
    <>
        <Helmet title="Contact" />
        <Layout>
            <div className="container">
                <h1 className="title">Contact</h1>
                <h2 className="subtitle">Send us a message!</h2>
                <form method="POST" action="/api/submit" encType="multipart/form-data">
                    <input type="hidden" name="form-name" value="contact" />
                    <p aria-hidden="true" class="is-hidden">
                        <label>
                        Don't fill this out if you're human: <input name="first-name" />
                        </label>
                    </p>
                    <ul className="form-style">
                        <li>
                            <label>
                                Full Name
                                <span className="required">*</span>
                            </label>
                            <input
                                name="name"
                                minLength="2"
                                className="field-long"
                                required
                            ></input>
                        </li>
                        <li>
                            <label>
                                Email <span className="required">*</span>
                            </label>
                            <input
                                type="email"
                                name="email"
                                className="field-long"
                                required
                            />
                        </li>
                        <li>
                            <label>
                                Contact Number <span className="required">*</span>
                            </label>
                            <input
                                type="tel"
                                name="phone"
                                className="field-long"
                                required
                            />
                        </li>
                        <li>
                            <label>Subject</label>
                            <select name="subject" className="field-select">
                                <option value="Enquiry">Enquiry</option>
                                <option value="Help">Help</option>
                            </select>
                        </li>
                        <li>
                            <label>
                                Your Message <span className="required">*</span>
                            </label>
                            <textarea
                                name="message"
                                id="message"
                                className="field-long field-textarea"
                                maxLength="200"
                                required
                            ></textarea>
                        </li>
                        <li>
                            <button type="Submit" value="Submit" >Submit</button>
                        </li>
                    </ul>
                </form>
            </div>
        </Layout>
    </>
);

export default Contact;
